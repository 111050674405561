<!--  -->
<template>
  <div class="orderTest-container">
    <el-dialog :title="title" :visible.sync="Universalshow" center>
      <el-button type="primary" @click="pinpaishow = !pinpaishow">
        添加
      </el-button>
      <br />
      <el-table stripe :data="tableData">
        <el-table-column
          type="index"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in columns"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '品类'" #default="{ row }">
            <el-input
              v-model="row.class_name"
              style="width: 150px"
              @blur="handlersort(row)"
            ></el-input>
          </template>
          <template v-else-if="list.label == '渠道'" #default="{ row }">
            <el-input
              v-model="row.channel_name"
              style="width: 220px"
              @blur="handlergonggong(row)"
            ></el-input>
          </template>
          <template v-else-if="list.label == '排序'" #default="{ row }">
            <el-input
              v-model="row.sort"
              style="width: 120px"
              @blur="handlersort(row)"
            ></el-input>
          </template>
          <template v-else-if="list.label == '客户等级'" #default="{ row }">
            <el-input
              v-model="row.class_name"
              style="width: 220px"
              @blur="handlergonggong(row)"
            ></el-input>
          </template>
          <template v-else-if="list.label == '拍照类型'" #default="{ row }">
            <el-input
              v-model="row.name"
              style="width: 220px"
              @blur="handlergonggong(row)"
            ></el-input>
          </template>
          <template v-else-if="list.label == '收款方式'" #default="{ row }">
            <el-input
              v-model="row.name"
              style="width: 220px"
              @blur="handlergonggong(row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220" align="center">
          <template #default="{ row }">
            <el-button type="text" @click="handlerdelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog width="30%" :visible.sync="pinpaishow" append-to-body>
        <el-form ref="form" :data="pinform" inline>
          <el-form-item label="名称" prop="brand_name">
            <el-input v-model="pinform.name" />
          </el-form-item>
          <el-form-item v-show="type == 1" label="序号" prop="sort">
            <el-input v-model="pinform.sort" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handlerpinp">确定</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Universalshow = !Universalshow">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        title: '',
        Universalshow: false,
        pinpaishow: false,
        tableData: [],
        pinform: {
          name: '',
          sort: '',
        },
        columns: [
          {
            label: '',
            align: '',
            prop: '',
            width: '',
          },
        ],
        type: '',
      }
    },

    computed: {},
    watch: {
      Universalshow(val) {
        if (!val) {
          this.$emit('getlist')
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async handlerpinp() {
        switch (this.type) {
          case 1:
            await this.handleraddlist('/superAdmin/goods-class/create', {
              class_name: this.pinform.name,
              sort: this.pinform.sort,
            }).then((res) => {
              this.handlerCategory()
            })

            break
          case 2:
            await this.handleraddlist('/superAdmin/customer-channel/create', {
              channel_name: this.pinform.name,
            }).then((res) => {
              this.handlerchannel()
            })

            break
          case 3:
            await this.handleraddlist('/superAdmin/customer-class/create', {
              class_name: this.pinform.name,
            }).then((res) => {
              this.handlerCustomer()
            })
            break
          case 4:
            await this.handleraddlist('/superAdmin/photo-type/photo-type-add', {
              name: this.pinform.name,
            }).then((res) => {
              this.handlerpictures()
            })
            break
          case 5:
            await this.handleraddlist('/superAdmin/pay/add', {
              name: this.pinform.name,
              sort: this.pinform.sort,
            }).then((res) => {
              this.handlerpay()
            })
            break
        }
      },
      async handlergonggong(row) {
        switch (this.type) {
          case 2:
            await this.handlerpost('/superAdmin/customer-channel/update', {
              id: row.id,
              channel_name: row.channel_name,
              sort_order: row.sort_order,
            }).then((res) => {
              this.handlerchannel()
            })

            break
          case 3:
            await this.handlerpost('/superAdmin/customer-class/update', {
              id: row.id,
              class_name: row.class_name,
              sort_order: row.sort_order,
            }).then((res) => {
              this.handlerCustomer()
            })
            break
          case 4:
            await this.handlerpost('/superAdmin/photo-type/photo-type-edit', {
              id: row.id,
              type_name: row.type_name,
              sort: row.sort,
            }).then((res) => {
              this.handlerpictures()
            })
            break
          case 5:
            await this.handlerpost('/superAdmin/pay/add', {
              id: row.id,
              name: row.name,
              sort: row.sort,
            }).then((res) => {
              this.handlerpay()
            })
            break
        }
      },
      handleraddlist(url, data) {
        let promise = new Promise((resolve, reject) => {
          postAction(url, data)
            .then((res) => {
              this.pinpaishow = false
              this.$message({ type: 'success', message: res.msg })
              this.pinform = {
                name: '',
                sort: '',
              }
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
        return promise
      },
      handlerpost(url, data) {
        let promise = new Promise((resolve, reject) => {
          postAction(url, data)
            .then((res) => {
              this.$message({ type: 'success', message: res.msg })
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
        return promise
      },
      handlersort(row) {
        console.log(row)
        var paramer = {
          class_name: row.class_name,
          id: row.id,
          sort: row.sort,
        }
        postAction('/superAdmin/goods-class/update', paramer).then((res) => {
          this.handlerCategory()
        })
      },
      async handlerdelete(row) {
        switch (this.type) {
          case 1:
            await this.handlerdeleted('/superAdmin/goods-class/delete', {
              id: row.id,
            }).then((res) => {
              this.handlerCategory()
            })

            break
          case 2:
            await this.handlerdeleted('/superAdmin/customer-channel/delete', {
              id: row.id,
            }).then((res) => {
              this.handlerchannel()
            })

            break
          case 3:
            await this.handlerdeleted('/superAdmin/customer-class/delete', {
              id: row.id,
            }).then((res) => {
              this.handlerCustomer()
            })
            break
          case 4:
            await this.handlerdeleted('/superAdmin/photo-type/photo-type-del', {
              id: row.id,
            }).then((res) => {
              this.handlerpictures()
            })
            break
          case 5:
            await this.handlerdeleted('/superAdmin/pay/delete', {
              id: row.id,
            }).then((res) => {
              this.handlerpay()
            })
            break
        }
      },
      handlerdeleted(url, data) {
        let promise = new Promise((resolve, reject) => {
          postAction(url, data)
            .then((res) => {
              this.$message({ type: 'success', message: res.msg })
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
        return promise
      },
      handlerCategory() {
        postAction('/superAdmin/goods-class/index', { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.columns = [
              {
                label: '排序',
                align: 'center',
                prop: 'sort',
                width: '',
              },
              {
                label: '品类',
                align: 'center',
                prop: 'class_name',
                width: '',
              },
            ]
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerchannel() {
        postAction('/superAdmin/customer-channel/index', { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.columns = [
              {
                label: '渠道',
                align: 'center',
                prop: 'channel_name',
                width: '',
              },
            ]
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerCustomer() {
        postAction('/superAdmin/customer-class/index', { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.columns = [
              {
                label: '客户等级',
                align: 'center',
                prop: 'class_name',
                width: '',
              },
            ]
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerpictures() {
        postAction('/superAdmin/photo-type/photo-type-list', { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.columns = [
              {
                label: '拍照类型',
                align: 'center',
                prop: 'name',
                width: '',
              },
            ]
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerpay() {
        postAction('/superAdmin/pay/list', { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.columns = [
              {
                label: '收款方式',
                align: 'center',
                prop: 'name',
                width: '',
              },
            ]
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
