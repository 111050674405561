var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.Universalshow, center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.Universalshow = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.pinpaishow = !_vm.pinpaishow
                },
              },
            },
            [_vm._v(" 添加 ")]
          ),
          _c("br"),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "80", align: "center" },
              }),
              _vm._l(_vm.columns, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "品类"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "150px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlersort(row)
                                    },
                                  },
                                  model: {
                                    value: row.class_name,
                                    callback: function ($$v) {
                                      _vm.$set(row, "class_name", $$v)
                                    },
                                    expression: "row.class_name",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "渠道"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlergonggong(row)
                                    },
                                  },
                                  model: {
                                    value: row.channel_name,
                                    callback: function ($$v) {
                                      _vm.$set(row, "channel_name", $$v)
                                    },
                                    expression: "row.channel_name",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlersort(row)
                                    },
                                  },
                                  model: {
                                    value: row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(row, "sort", $$v)
                                    },
                                    expression: "row.sort",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "客户等级"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlergonggong(row)
                                    },
                                  },
                                  model: {
                                    value: row.class_name,
                                    callback: function ($$v) {
                                      _vm.$set(row, "class_name", $$v)
                                    },
                                    expression: "row.class_name",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "拍照类型"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlergonggong(row)
                                    },
                                  },
                                  model: {
                                    value: row.name,
                                    callback: function ($$v) {
                                      _vm.$set(row, "name", $$v)
                                    },
                                    expression: "row.name",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "收款方式"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "220px" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlergonggong(row)
                                    },
                                  },
                                  model: {
                                    value: row.name,
                                    callback: function ($$v) {
                                      _vm.$set(row, "name", $$v)
                                    },
                                    expression: "row.name",
                                  },
                                }),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "220", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerdelete(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                visible: _vm.pinpaishow,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.pinpaishow = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { data: _vm.pinform, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "brand_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.pinform.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.pinform, "name", $$v)
                          },
                          expression: "pinform.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type == 1,
                          expression: "type == 1",
                        },
                      ],
                      attrs: { label: "序号", prop: "sort" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.pinform.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.pinform, "sort", $$v)
                          },
                          expression: "pinform.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerpinp },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.Universalshow = !_vm.Universalshow
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }